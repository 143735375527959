import React, { useMemo } from "react";
import { Editor } from "@tinymce/tinymce-react";
import wordFilter from "tinymce-word-paste-filter";

export const HTMLEditor = ({ value, onChange, readOnly, height }) => {
  const config = useMemo(
    () => ({
      language_url: "/tinymce/langs/tinymce-lang-de.js",
      language: "de",
      license_key: "gpl",
      height: height || 300,
      menubar: "edit",
      toolbar: [
        "undo redo | formatselect styles | fontselect fontsizeselect |",
        "bold italic underline | alignleft aligncenter alignright alignjustify |",
        "bullist numlist outdent indent | forecolor backcolor",
      ].join(" "),
      plugins: ["lists"],
      promotion: false,
      paste_preprocess: (_plugin, args) => {
        const filteredContent = wordFilter(args.content);

        args.content = filteredContent;
      },
    }),
    [height]
  );

  return (
    <Editor
      tinymceScriptSrc={"/tinymce/tinymce.min.js"}
      value={value}
      disabled={readOnly}
      init={config}
      onEditorChange={onChange}
    />
  );
};
